import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../config';
import { CircularProgress } from '@mui/material';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import { Edit, Trash, Plus } from 'lucide-react';
import axios from 'axios';

function UserDashboard() {
  const { getAccessTokenSilently, user, isLoading: authLoading } = useAuth0();

  const [apiKeys, setApiKeys] = useState({
    openai: '',    
    anthropic: '', 
    fal: ''        
  });

  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [siteForm, setSiteForm] = useState({
    name: '',
    url: '',
    api_url: '',
    username: '',
    password: ''
  });

  const formatWordPressApiUrl = (url) => {
    // Remove trailing slashes
    let cleanUrl = url.trim().replace(/\/+$/, '');
    
    // If the URL doesn't end with wp-json/wp/v2, add it
    if (!cleanUrl.endsWith('wp-json/wp/v2')) {
        // If it doesn't have wp-json at all
        if (!cleanUrl.includes('wp-json')) {
            cleanUrl = `${cleanUrl}/wp-json/wp/v2`;
        }
        // If it has wp-json but not wp/v2
        else if (!cleanUrl.endsWith('wp/v2')) {
            cleanUrl = `${cleanUrl}/wp/v2`;
        }
    }
    return cleanUrl;
};

  // Effect hooks
  useEffect(() => {
    if (!authLoading) {
      fetchWordPressSites();
      fetchApiKeys();
    }
  }, [authLoading]);

  const fetchWordPressSites = async () => {
    try {
      setIsLoading(true);
      setLoadingError(null);
      
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://TrevoWriter',
          scope: 'openid profile email offline_access'
        }
      });
      
      // Create a default axios instance with the base configuration
      const api = axios.create({
        baseURL: config.api.baseUrl,
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      const response = await api.get('/api/user/wordpress-sites');
      setSites(response.data);
    } catch (error) {
      console.error("Error fetching sites:", error);
      setLoadingError(
        error.response?.data?.detail || 
        'Error loading WordPress sites. Please try again.'
      );
      setNotification({
        open: true,
        message: 'Error loading WordPress sites',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchApiKeys = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://TrevoWriter',
          scope: 'openid profile email offline_access'
        }
      });

      const response = await axios.get(`${config.api.baseUrl}/api/user/api-keys`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setApiKeys(response.data);
    } catch (error) {
      console.error('Error fetching API keys:', error);
      setNotification({
        open: true,
        message: 'Error loading API keys',
        severity: 'error'
      });
    }
  };

  
  const handleApiKeysSave = async (e) => {
    e.preventDefault();
    try {
        console.log('API Keys being sent:', apiKeys); // Add this line
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: 'https://TrevoWriter',
                scope: 'openid profile email offline_access'
            }
        });
        
        await axios.post(`${config.api.baseUrl}/api/user/api-keys`, apiKeys, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        setNotification({
            open: true,
            message: 'API keys saved successfully',
            severity: 'success'
        });
    } catch (error) {
        console.error('API Keys Save Error:', error);
        setNotification({
            open: true,
            message: error.response?.data?.detail || 'Failed to save API keys',
            severity: 'error'
        });
    }
};


  const handleOpenSiteDialog = (site = null) => {
    if (site) {
      setSiteForm(site);
      setSelectedSite(site);
    } else {
      setSiteForm({
        name: '',
        url: '',
        api_url: '',
        username: '',
        password: ''
      });
      setSelectedSite(null);
    }
    setOpenDialog(true);
  };

  const handleCloseSiteDialog = () => {
    setOpenDialog(false);
    setSelectedSite(null);
  };

  const handleSaveSite = async (e) => {
    e.preventDefault();
    try {
        const formattedSiteForm = {
            ...siteForm,
            api_url: formatWordPressApiUrl(siteForm.api_url),
            url: siteForm.url.trim().replace(/\/+$/, '')
        };

        console.log("Starting site save process...");

        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: 'https://TrevoWriter',
                scope: 'openid profile email offline_access'
            }
        });

        const url = selectedSite
            ? `${config.api.baseUrl}/api/user/wordpress-sites/${selectedSite.id}`
            : `${config.api.baseUrl}/api/user/wordpress-sites`;

        console.log("With data:", {
            ...formattedSiteForm,
            password: '[REDACTED]'
        });

        const response = await axios.request({
            url,
            method: selectedSite ? 'PUT' : 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: formattedSiteForm
        });

        console.log("Response received:", response.data);

        // Ensure response status is a success
        if (response.status >= 200 && response.status < 300) {
            fetchWordPressSites();
            handleCloseSiteDialog();
            setNotification({
                open: true,
                message: selectedSite ? 'Site updated successfully' : 'Site added successfully',
                severity: 'success'
            });
        } else {
            console.error("Unexpected response status:", response.status);
            throw new Error("Unexpected response from the server.");
        }
    } catch (error) {
        console.error("Error saving site:", error);

        // Improve error message clarity
        const errorMessage = error.response?.data?.message || 
                             error.response?.data?.detail || 
                             'Error saving WordPress site';

        setNotification({
            open: true,
            message: errorMessage,
            severity: 'error'
        });
    }
};


  const handleDeleteSite = async (siteId) => {
    if (window.confirm('Are you sure you want to delete this site?')) {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://TrevoWriter',
            scope: 'openid profile email offline_access'
          }
        });
        await axios.delete(`${config.api.baseUrl}/api/user/wordpress-sites/${siteId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        fetchWordPressSites();
        setNotification({
          open: true,
          message: 'Site deleted successfully',
          severity: 'success'
        });
      } catch (error) {
        setNotification({
          open: true,
          message: 'Error deleting WordPress site',
          severity: 'error'
        });
      }
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Render
  return (
    <Box sx={{ p: 3 }}>
  
  <Paper sx={{ p: 3, mb: 3 }}>
  <Typography variant="h6" gutterBottom>
    User Details
  </Typography>
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      <Typography variant="subtitle1" color="text.secondary">
        Name
      </Typography>
      <Typography variant="body1">
        {user?.name}
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <Typography variant="subtitle1" color="text.secondary">
        Email
      </Typography>
      <Typography variant="body1">
        {user?.email}
      </Typography>
    </Grid>
  </Grid>
</Paper>

      {/* API Keys Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          API Keys
        </Typography>
        <form onSubmit={handleApiKeysSave}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="OpenAI API Key"
                type="password"
                value={apiKeys.openai}
                onChange={(e) => setApiKeys({ ...apiKeys, openai: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Anthropic API Key"
                type="password"
                value={apiKeys.anthropic}
                onChange={(e) => setApiKeys({ ...apiKeys, anthropic: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fal.ai API Key"
                type="password"
                value={apiKeys.fal}
                onChange={(e) => setApiKeys({ ...apiKeys, fal: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Save API Keys
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {/* WordPress Sites Section */}
      {/* WordPress Sites Section */}
      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">
            WordPress Sites
          </Typography>
          <Button
            variant="contained"
            startIcon={<Plus />}
            onClick={() => handleOpenSiteDialog()}
          >
            Add Site
          </Button>
        </Box>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : loadingError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {loadingError}
          </Alert>
        ) : sites.length === 0 ? (
          <Alert severity="info" sx={{ mb: 2 }}>
            No WordPress sites added yet. Click "Add Site" to get started.
          </Alert>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {sites.map((site) => (
              <Card key={site.id} sx={{ width: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <img 
                      src={`${site.url}/favicon.ico`}
                      alt=""
                      onError={(e) => {
                        e.target.src = `https://www.google.com/s2/favicons?domain=${site.url}`;
                      }}
                      style={{
                        width: '16px',
                        height: '16px',
                        objectFit: 'contain'
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" component="div">
                        {site.name}
                      </Typography>
                      <Typography color="text.secondary">
                        {site.url}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton onClick={() => handleOpenSiteDialog(site)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteSite(site.id)}>
                        <Trash />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </Paper>

      {/* WordPress Site Dialog */}
      <Dialog open={openDialog} onClose={handleCloseSiteDialog} maxWidth="sm" fullWidth>
        <form onSubmit={handleSaveSite}>
          <DialogTitle>
            {selectedSite ? 'Edit WordPress Site' : 'Add WordPress Site'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Site Name"
                  value={siteForm.name}
                  onChange={(e) => setSiteForm({ ...siteForm, name: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Site URL"
                  value={siteForm.url}
                  onChange={(e) => setSiteForm({ ...siteForm, url: e.target.value })}
                  required
                  helperText="Example: https://your-site.com"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="API URL"
                  value={siteForm.api_url}
                  onChange={(e) => setSiteForm({ ...siteForm, api_url: e.target.value })}
                  required
                  helperText="Example: https://your-site.com/wp-json/wp/v2"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  value={siteForm.username}
                  onChange={(e) => setSiteForm({ ...siteForm, username: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  value={siteForm.password}
                  onChange={(e) => setSiteForm({ ...siteForm, password: e.target.value })}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSiteDialog}>Cancel</Button>
            <Button type="submit" variant="contained">
              {selectedSite ? 'Save Changes' : 'Add Site'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default UserDashboard;