import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../config';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Alert,
  IconButton,
  Collapse
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';

export default function SavedPrompts() {
  const [prompts, setPrompts] = useState([]);
  const [expandedPrompt, setExpandedPrompt] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchPrompts();
  }, []);

  const fetchPrompts = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(`${config.api.baseUrl}/api/user/prompts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch prompts');
      const data = await response.json();
      setPrompts(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${config.api.baseUrl}/api/user/prompts/${promptToDelete}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to delete prompt');
      setPrompts(prompts.filter(prompt => prompt.id !== promptToDelete));
      setDeleteDialogOpen(false);
      setPromptToDelete(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = (promptId) => {
    window.location.href = `/prompts/edit/${promptId}`;
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: '1200px', mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Saved Prompts
      </Typography>
      
      {prompts.length === 0 ? (
        <Box sx={{ textAlign: 'center', p: 4, color: 'text.secondary' }}>
          <Typography>
            No saved prompts found. Create a new prompt to get started.
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {prompts.map((prompt) => (
            <Card key={prompt.id}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {prompt.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Created: {new Date(prompt.created_at).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton onClick={() => handleEdit(prompt.id)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => {
                        setPromptToDelete(prompt.id);
                        setDeleteDialogOpen(true);
                      }}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => setExpandedPrompt(
                        expandedPrompt === prompt.id ? null : prompt.id
                      )}
                      size="small"
                    >
                      {expandedPrompt === prompt.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </Box>
                </Box>
                <Collapse in={expandedPrompt === prompt.id}>
                  <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                      {prompt.prompt_text}
                    </Typography>
                  </Box>
                </Collapse>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Prompt</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this prompt? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}