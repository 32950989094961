import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import PromptBuilder from './components/PromptBuilder'; 
import { Edit } from 'lucide-react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon, 
  ListItemText,
  Typography,
  CssBaseline,
  IconButton,
  AppBar,
  Toolbar,
  Container,
  CircularProgress,
  Button
} from '@mui/material';
import {
  Description as ArticleIcon,
  Image as ImageIcon, 
  Dashboard as DashboardIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import SavedPrompts from './components/SavedPrompts';
import ArticleGenerator from './components/ArticleGenerator';
import ImageGenerator from './components/ImageGenerator';
import UserDashboard from './components/UserDashboard';
import { useAuth0 } from '@auth0/auth0-react';
import Callback from './components/Callback';
import config from './config'; 
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';


const drawerWidth = 240;

function PageTitle() {
  const location = useLocation();
  switch (location.pathname) {
    case '/':
      return 'Article Generator';
    case '/images':
      return 'Image Generator'; 
    case '/dashboard':
      return 'Dashboard';
    default:
      return 'AI Generator';
  }
}

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [promptMenuOpen, setPromptMenuOpen] = useState(false);
  const { isLoading, error, isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Authentication Error: {error.message}</div>;
  }

  if (!isAuthenticated) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
             Treevowriter
          </Typography>
          <Button 
            variant="contained" 
            size="large"
            onClick={() => loginWithRedirect()}
          >
            Log In
          </Button>
        </Box>
      </Container>
    );
  }



  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
      clearLocalStorage: true
    });
  };

  const drawer = (
    <Box>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          <img
            src="/TreevowriterLogo.webp"
            alt="Treevowriter Logo"
            style={{
              height: '70px',
              width: 'auto'
            }}
          />
        </Box>
      </Toolbar>
      
      <List>
        {/* Prompts Menu */}
        <ListItem 
          button 
          onClick={() => setPromptMenuOpen(!promptMenuOpen)}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Prompts" />
          {promptMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={promptMenuOpen} timeout="auto" unmountOnMount>
          <List component="div" disablePadding>
            <ListItem
              component={Link}
              to="/prompts/new"
              sx={{
                pl: 4,
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
              }}
            >
              <ListItemText primary="New Prompt" />
            </ListItem>
            <ListItem
              component={Link}
              to="/prompts/saved"
              sx={{
                pl: 4,
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
              }}
            >
              <ListItemText primary="Saved Prompts" />
            </ListItem>
          </List>
        </Collapse>

        {/* Other Menu Items */}
        <ListItem
          component={Link}
          to="/"
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          }}
        >
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary="Article Writer" />
        </ListItem>
        <ListItem
          component={Link}
          to="/images"
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          }}
        >
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText primary="Image Generator" />
        </ListItem>
        <ListItem
          component={Link}
          to="/dashboard"
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
        <ListItem
          button
          onClick={handleLogout}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
              <PageTitle />
            </Typography>
            {user && (
              <Typography variant="subtitle1">
                Welcome, {user.name}
              </Typography>
            )}
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            mt: { xs: 7, sm: 8 },
          }}
        >
          <Container maxWidth="lg">
            <Routes>
              <Route path="/" element={<ArticleGenerator />} />
              <Route path="/prompts/new" element={<PromptBuilder config={config} />} />
              <Route path="/prompts/saved" element={<SavedPrompts />} />
              <Route path="/images" element={<ImageGenerator />} />
              <Route path="/dashboard" element={<UserDashboard />} />
              <Route path="/callback" element={<Callback />} />
            </Routes>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;