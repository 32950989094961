import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="dev-37mvwswd3e73pa07.eu.auth0.com"
        clientId="i4jaANoox38PiYlyOTC5e2XY8NlctjnC"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://TrevoWriter",
          scope: "openid profile email offline_access",
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);