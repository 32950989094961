import React, { useState, useEffect } from 'react';
import { config } from '../config';
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Collapse,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Pending as PendingIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Eye } from 'lucide-react';

function ArticleGenerator() {
  const { getAccessTokenSilently } = useAuth0();

  // Sites and Categories State
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  // Form State
  const [includeFAQ, setIncludeFAQ] = useState(true);
  const [titles, setTitles] = useState('');
  const [model, setModel] = useState('claude');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Queue State
  const [queuedArticles, setQueuedArticles] = useState([]);
  
  // Image Settings State
  const [includeImages, setIncludeImages] = useState(false);
  const [imageModel, setImageModel] = useState('fal-ai/flux/schnell');
  const [imageFrequency, setImageFrequency] = useState('every');

  // Prompt and Dialog State
  const [openPromptDialog, setOpenPromptDialog] = useState(false);
  const [tempPrompt, setTempPrompt] = useState('');
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPrompt, setPreviewPrompt] = useState(null);

  // Validation State
  const [titlesTouched, setTitlesTouched] = useState(false);
  const hasTitles = titles.trim().length > 0;
  const showTitleError = titlesTouched && !hasTitles;

  const basePrompt = `Please write a 1200 word article using the following guidelines:
  1. Do not repeat the article title at the beginning of the article
  2. Maintain a friendly, conversational tone throughout the piece.
  3. Format all main section headings as H2 (##) using clear, informative questions.
  4. Include specific details, facts, and figures where relevant.
  5. Avoid slang and overly casual language, but keep the writing accessible and engaging.
  6. Present information objectively, minimizing editorializing.
  7. Incorporate a balanced mix of technical details and easy-to-understand explanations.
  8. Use short paragraphs and varied sentence structures to maintain reader interest.
  9. Include a short, friendly introduction that engages the reader and sets a conversational tone.
  10. Do not use lists or bullet points and keep the number of subheadings to a minimum.
  11. Do not include a conclusion at the end of the article.
  12. The intended audience is people interested in animals, pet owners, potential pet owners, and students from age 10 upwards.`;
  
  const faqSection = `
  13. For the FAQ section:
      - Use 'Frequently Asked Questions' as an H2 heading (##)
      - Format each question as a numbered H3 heading (###)
      - Number the questions from 1 to 5`;

  const [prompt, setPrompt] = useState(basePrompt + faqSection);

  const models = [
    { value: 'claude', label: 'Claude 3.5' },
    { value: 'gpt-4', label: 'GPT-4' }
  ];

  const imageModels = [
    { value: 'fal-ai/flux/schnell', label: 'FLUX.1 Schnell' },
    { value: 'fal-ai/dev', label: 'FLUX.1 Dev' },
    { value: 'fal-ai/recraft-v3', label: 'Recraft V3' },
    { value: 'fal-ai/flux-pro/v1.1', label: 'FLUX 1.1 Pro' },
    { value: 'fal-ai/flux-realism', label: 'Flux Realism' }  
  ];

  const imageFrequencies = [
    { value: 'every', label: 'Every H2 Heading' },
    { value: 'alternate', label: 'Every Other H2 Heading' }
  ];

  // Initialize queue with titles
  const initializeQueue = (titleList) => {
    const newQueue = titleList.map(title => ({
      title,
      status: 'queued',
      message: 'Waiting to start...'
    }));
    setQueuedArticles(newQueue);
  };

  // Update article status in queue
  const updateArticleStatus = (title, status, message) => {
    setQueuedArticles(prev =>
      prev.map(article =>
        article.title === title
          ? { ...article, status, message }
          : article
      )
    );
  };

  const handleSavePrompt = () => {
    if (includeFAQ) {
      setPrompt(tempPrompt + faqSection);
    } else {
      setPrompt(tempPrompt);
    }
    setOpenPromptDialog(false);
  };

  // Effects
  useEffect(() => {
    fetchSavedPrompts();
    fetchWordPressSites();
  }, []);

  useEffect(() => {
    if (selectedSite) {
      fetchCategories(selectedSite);
    }
  }, [selectedSite]);

  useEffect(() => {
    if (includeFAQ) {
      if (!prompt.includes('For the FAQ section:')) {
        setPrompt(prompt + faqSection);
      }
    } else {
      setPrompt(prompt.replace(faqSection, ''));
    }
  }, [includeFAQ]);

  // API Functions
  const fetchSavedPrompts = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${config.api.baseUrl}/api/user/prompts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setSavedPrompts(data);
    } catch (error) {
      console.error('Error fetching prompts:', error);
    }
  };

  const fetchWordPressSites = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${config.api.baseUrl}/api/user/wordpress-sites`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setSites(data);
      if (data.length > 0) {
        setSelectedSite(data[0].id);
      }
    } catch (error) {
      setError('Failed to load WordPress sites');
    }
  };

  const fetchCategories = async (siteId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${config.api.baseUrl}/api/user/wordpress-sites/${siteId}/categories`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setCategories(data);
      setCategory('');
    } catch (error) {
      setError('Failed to load categories');
    }
  };

  // Event Handlers
  const handlePreviewPrompt = async (promptId) => {
    const prompt = savedPrompts.find(p => p.id === promptId);
    if (prompt) {
      setPreviewPrompt(prompt);
      setPreviewOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    const titleList = titles.split('\n').filter(title => title.trim());
    const selectedPromptTemplate = savedPrompts.find(p => p.id === selectedPrompt);
    
    if (!selectedPromptTemplate) {
      setError('Please select a writing template');
      setLoading(false);
      return;
    }

    // Initialize queue with all titles
    initializeQueue(titleList);

    for (let i = 0; i < titleList.length; i++) {
      const title = titleList[i].trim();
      if (!title) continue;

      try {
        // Update status to processing
        updateArticleStatus(title, 'processing', 'Generating article content...');

        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${config.api.baseUrl}/api/user/generate-article`,
          {
            title,
            siteId: selectedSite,
            category,
            model,
            prompt: selectedPromptTemplate.prompt_text,
            includeImages,
            imageModel: includeImages ? imageModel : null,
            imageFrequency: includeImages ? imageFrequency : null
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
            timeout: 300000
          }
        );

        if (response.data.status === 'success') {
          updateArticleStatus(
            title,
            'completed',
            `Created successfully (Post ID: ${response.data.post_id})`
          );
        }
      } catch (err) {
        console.error('Error details:', err);
        updateArticleStatus(
          title,
          'error',
          err.response?.data?.detail || 'Failed to create article'
        );
      }
    }

    setLoading(false);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircleIcon className="text-green-500" />;
      case 'error':
        return <ErrorIcon className="text-red-500" />;
      case 'processing':
        return <CircularProgress size={24} />;
      default:
        return <PendingIcon className="text-gray-400" />;
    }
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Left Column - Form Controls */}
        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Select A WordPress Site</InputLabel>
                    <Select
                      value={selectedSite}
                      label="WordPress Site"
                      onChange={(e) => setSelectedSite(e.target.value)}
                      disabled={loading}
                    >
                      {sites.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Select A Category</InputLabel>
                    <Select
                      value={category}
                      label="Category"
                      onChange={(e) => setCategory(e.target.value)}
                      disabled={loading || !selectedSite}
                    >
                      {categories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FormControl fullWidth required>
                      <InputLabel>Writing Style Template</InputLabel>
                      <Select
                        value={selectedPrompt}
                        label="Writing Style Template"
                        onChange={(e) => setSelectedPrompt(e.target.value)}
                        disabled={loading}
                      >
                        {savedPrompts.map((prompt) => (
                          <MenuItem key={prompt.id} value={prompt.id}>
                            {prompt.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {selectedPrompt && (
                      <IconButton 
                        onClick={() => handlePreviewPrompt(selectedPrompt)}
                        sx={{ ml: 1 }}
                      >
                        <Eye />
                      </IconButton>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Article Titles (one per line max 10)"
                    multiline
                    rows={5}
                    value={titles}
                    onChange={(e) => setTitles(e.target.value)}
                    onBlur={() => setTitlesTouched(true)}
                    required
                    disabled={loading}
                    placeholder="Enter article titles, one per line"
                    error={showTitleError}
                    helperText={showTitleError ? "At least one article title is required" : ""}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>AI Model</InputLabel>
                    <Select
                      value={model}
                      label="AI Model"
                      onChange={(e) => setModel(e.target.value)}
                      required
                      disabled={loading}
                    >
                      {models.map((modelOption) => (
                        <MenuItem key={modelOption.value} value={modelOption.value}>
                          {modelOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeImages}
                        onChange={(e) => setIncludeImages(e.target.checked)}
                        disabled={loading}
                      />
                    }
                    label="Include Images in Articles"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeFAQ}
                        onChange={(e) => setIncludeFAQ(e.target.checked)}
                        disabled={loading}
                      />
                    }
                    label="Include FAQ Section"
                  />
                </Grid>

                {includeImages && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>Image Generation Model</InputLabel>
                        <Select
                          value={imageModel}
                          label="Image Generation Model"
                          onChange={(e) => setImageModel(e.target.value)}
                          disabled={loading}
                        >
                          {imageModels.map((model) => (
                            <MenuItem key={model.value} value={model.value}>
                              {model.label}
                            </MenuItem>
                            ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel>Image Frequency</InputLabel>
                            <Select
                              value={imageFrequency}
                              label="Image Frequency"
                              onChange={(e) => setImageFrequency(e.target.value)}
                              disabled={loading}
                            >
                              {imageFrequencies.map((freq) => (
                                <MenuItem key={freq.value} value={freq.value}>
                                  {freq.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
    
                  {error && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Alert severity="error">
                        {typeof error === 'string' ? error : 'An error occurred'}
                      </Alert>
                    </Box>
                  )}
    
                  <Box sx={{ mt: 2 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!selectedSite || !category || !selectedPrompt || !hasTitles || loading}
                      fullWidth
                    >
                      {loading ? 'Generating Articles...' : 'Generate Articles'}
                    </Button>
                  </Box>
                </form>
              </Paper>
            </Grid>
    
            {/* Right Column - Article Queue */}
            <Grid item xs={12} md={5}>
              <Paper sx={{ p: 3, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Article Queue
                </Typography>
                {queuedArticles.length === 0 ? (
                  <Box sx={{ textAlign: 'center', py: 8 }}>
                    <Typography color="textSecondary">
                      No articles in queue. Add titles and click "Generate Articles" to begin.
                    </Typography>
                  </Box>
                ) : (
                  <List>
                    {queuedArticles.map((article, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'divider',
                          '&:last-child': {
                            borderBottom: 'none'
                          },
                          py: 2
                        }}
                      >
                        <ListItemIcon>
                          {getStatusIcon(article.status)}
                        </ListItemIcon>
                        <ListItemText
                          primary={article.title}
                          secondary={article.message}
                          primaryTypographyProps={{
                            fontWeight: 500
                          }}
                          secondaryTypographyProps={{
                            fontSize: '0.875rem'
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Paper>
            </Grid>
          </Grid>
    
          {/* Preview Dialog */}
          <Dialog
            open={previewOpen}
            onClose={() => setPreviewOpen(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              {previewPrompt?.name}
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                {previewPrompt?.prompt_text}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPreviewOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
    
          {/* Prompt Edit Dialog */}
          <Dialog
            open={openPromptDialog}
            onClose={() => setOpenPromptDialog(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Edit Prompt Template</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                multiline
                rows={15}
                value={tempPrompt}
                onChange={(e) => setTempPrompt(e.target.value)}
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenPromptDialog(false)}>Cancel</Button>
              <Button onClick={handleSavePrompt} variant="contained">
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      );
    }
    
    export default ArticleGenerator;