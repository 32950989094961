import React, { useState, useEffect } from 'react';
import { Grid, TextField, Select, MenuItem, Button, Typography, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const PromptBuilder = ({ config }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } = useAuth0();
  
  const [settings, setSettings] = useState({
    wordCount: 1000,
    tone: 'friendly',
    headingStyle: 'seo',
    conclusion: false,
    faq: false,
    paragraphStyle: 'mix',
    articleType: 'blog',
    model: ''
  });

  const [customPrompt, setCustomPrompt] = useState('');
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [promptName, setPromptName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);

useEffect(() => {
    const fetchModels = async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${config.api.baseUrl}/api/user/available-models`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const models = await response.json();
            setAvailableModels(models);
           
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    if (isAuthenticated) {
        fetchModels();
    }
}, [isAuthenticated, getAccessTokenSilently, config.api.baseUrl]);

  // Add debugging useEffect
  useEffect(() => {
    console.log('Auth0 State:', {
      isAuthenticated,
      isLoading,
      hasUser: !!user,
      hasGetAccessTokenSilently: !!getAccessTokenSilently
    });

    const checkToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log('Token retrieved successfully:', !!token);
      } catch (error) {
        console.error('Token retrieval error:', error);
      }
    };
    
    if (isAuthenticated) {
      checkToken();
    }
  }, [isAuthenticated, getAccessTokenSilently, user]);

  const handleBuildPrompt = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      console.log('Build Prompt - Token retrieved:', !!token);

      const payload = {
        wordCount: settings.wordCount,
        tone: settings.tone,
        headingStyle: settings.headingStyle,
        paragraphStyle: settings.paragraphStyle,
        articleType: settings.articleType,
        customInput: customPrompt,
        model: settings.model
      };

      console.log('Sending payload:', payload); // Debug log

      const response = await fetch(`${config.api.baseUrl}/api/user/generate-prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      if (response.ok) {
        setGeneratedPrompt(data.prompt);
      } else {
        throw new Error(data.detail);
      }
    } catch (err) {
      console.error('Build Prompt Error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
};

  const handleSavePrompt = async () => {
    try {
      console.log('Save Prompt - Getting token...');
      const token = await getAccessTokenSilently();
      console.log('Save Prompt - Token retrieved:', !!token);

      const response = await fetch(`${config.api.baseUrl}/api/user/prompts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name: promptName,
          prompt_text: generatedPrompt,
          settings: settings
        })
      });

      if (!response.ok) {
        throw new Error('Failed to save prompt');
      }

      setPromptName('');
      setGeneratedPrompt('');
      setCustomPrompt('');
    } catch (error) {
      console.error('Save Prompt Error:', error);
      setError('Failed to save prompt: ' + error.message);
    }
  };

  const handleClear = () => {
    setCustomPrompt('');
    setGeneratedPrompt('');
    setPromptName('');
    setSettings({
      wordCount: 1000,
      tone: 'friendly',
      headingStyle: 'seo',
      conclusion: false,
      faq: false,
      paragraphStyle: 'mix',
      articleType: 'blog',
      model: 'claude'
    });
  };

  // Add loading state handling
  if (isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  // Add authentication check
  if (!isAuthenticated) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography>Please log in to use this feature</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Prompt Builder
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Custom Instructions"
            multiline
            rows={3}
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            fullWidth
            helperText="Provide specific details or topics for the prompt (max 200 characters)."
          />
        </Grid>
        <Grid item xs={12}>
    <Select
        value={settings.model}
        onChange={(e) => setSettings({...settings, model: e.target.value})}
        fullWidth
        displayEmpty
        required
        sx={{ 
            '& .MuiSelect-select.MuiSelect-outlined': {
                fontStyle: 'normal'  // Removes italics
            }
        }}
    >
        <MenuItem disabled value="" sx={{ fontStyle: 'normal' }}>  {/* Removes italics */}
            Choose an AI model
        </MenuItem>
        {availableModels.map((model) => (
            <MenuItem key={model.value} value={model.value}>
                {model.label}
            </MenuItem>
        ))}
    </Select>
</Grid>


        <Grid item xs={6}>
          <TextField
            label="Word Count"
            type="number"
            value={settings.wordCount}
            onChange={(e) => setSettings({ ...settings, wordCount: e.target.value })}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <Select
            value={settings.tone}
            fullWidth
            onChange={(e) => setSettings({ ...settings, tone: e.target.value })}
            displayEmpty
          >
            <MenuItem value="friendly">Friendly</MenuItem>
            <MenuItem value="professional">Professional</MenuItem>
            <MenuItem value="academic">Academic</MenuItem>
            <MenuItem value="casual">Casual</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Select
            value={settings.headingStyle}
            fullWidth
            onChange={(e) => setSettings({ ...settings, headingStyle: e.target.value })}
            displayEmpty
          >
            <MenuItem value="seo">SEO Friendly Questions</MenuItem>
            <MenuItem value="standard">Standard Headings</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Select
            value={settings.paragraphStyle}
            fullWidth
            onChange={(e) => setSettings({ ...settings, paragraphStyle: e.target.value })}
            displayEmpty
          >
            <MenuItem value="short">Short Paragraphs</MenuItem>
            <MenuItem value="long">Long Paragraphs</MenuItem>
            <MenuItem value="mix">Mixed Paragraphs</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBuildPrompt}
            disabled={Boolean(!customPrompt || loading || !settings.model || settings.model === '')}  // More explicit check
          >
            {loading ? 'Generating...' : 'Generate Prompt'}
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClear} sx={{ ml: 2 }}>
            Clear
          </Button>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}

        {generatedPrompt && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Generated Prompt
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={6}
              value={generatedPrompt}
              onChange={(e) => setGeneratedPrompt(e.target.value)}
            />
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <TextField
                label="Template Name"
                value={promptName}
                onChange={(e) => setPromptName(e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSavePrompt}
                disabled={!promptName.trim()}
              >
                Save Template
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PromptBuilder;
