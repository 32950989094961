import React, { useState } from 'react';
import { config } from '../config';
import {
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import axios from 'axios';

function ImageGenerator() {
  const [prompt, setPrompt] = useState('');
  const [model, setModel] = useState('fal-ai/flux/schnell');
  const [useCustomPrompt, setUseCustomPrompt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);

  const imageModels = [
    { value: 'fal-ai/flux/schnell', label: 'FLUX.1 Schnell' },
    { value: 'fal-ai/dev', label: 'FLUX.1 Dev' },
    { value: 'fal-ai/recraft-v3', label: 'Recraft V3' },
    { value: 'fal-ai/flux-pro/v1.1', label: 'FLUX 1.1 Pro' },
    { value: 'fal-ai/flux-realism', label: 'Flux Realism' }  
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setGeneratedImage(null);

    try {
      const response = await axios.post(`${config.api.baseUrl}/api/generate-image`, {
        prompt,
        model,
        useCustomPrompt
      });

      if (response.data.image_url) {
        setGeneratedImage(response.data.image_url);
      } else {
        setError('No image was generated');
      }
    } catch (err) {
      setError(err.response?.data?.detail || err.message || 'Failed to generate image');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveImage = async () => {
    try {
      const response = await fetch(generatedImage);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `generated-image-${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Failed to download image');
    }
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>           
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useCustomPrompt}
                    onChange={(e) => setUseCustomPrompt(e.target.checked)}
                    disabled={loading}
                  />
                }
                label="Use direct description (bypass GPT-4 prompt enhancement)"
                sx={{ mb: 2 }}
              />
               <TextField
    fullWidth
    label={useCustomPrompt ? "Direct Image Description" : "Image Description (will be enhanced by GPT-4)"}
    multiline
    rows={4}
    value={prompt}
    onChange={(e) => setPrompt(e.target.value)}
    required
    disabled={loading}
    placeholder={useCustomPrompt ? 
      "Enter your exact prompt for the image model..." : 
      "Describe the image you want to generate..."
    }
  />

            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Image Model</InputLabel>
                <Select
                  value={model}
                  label="Image Model"
                  onChange={(e) => setModel(e.target.value)}
                  required
                  disabled={loading}
                >
                  {imageModels.map((modelOption) => (
                    <MenuItem key={modelOption.value} value={modelOption.value}>
                      {modelOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}

              <Grid item xs={12}>
                <Button
                   type="submit"
                   variant="contained"
                   fullWidth
                >
                  {loading ? <CircularProgress size={24} /> : 'Generate Image'}
                </Button>
            
           </Grid>
         </form>
      </Paper>

      {generatedImage && (
        <Paper sx={{ p: 3 }}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Generated Image</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={handleSaveImage}
            >
              Save Image
            </Button>
          </Box>
          <Box
            component="img"
            sx={{
              width: '100%',
              height: 'auto',
              maxWidth: '600px',
              display: 'block',
              margin: '0 auto',
              borderRadius: 1,
            }}
            src={generatedImage}
            alt="Generated image"
          />
        </Paper>
      )}
    </Box>
  );
}

export default ImageGenerator;